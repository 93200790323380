<template>
  <div>
    <b-card>
      <title-text />
      <summary-content />
    </b-card>
    <b-row class="match-height">
      <b-col
        cols="12"
        md="6"
      >
        <image-card />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <file-card />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow,
} from 'bootstrap-vue'
import TitleText from '@/views/Admin/Corporate_materials/elements/TitleText.vue'
import SummaryContent from '@/views/Admin/Corporate_materials/elements/SummaryContent.vue'
import ImageCard from '@/views/Admin/Corporate_materials/elements/ImageCard.vue'
import FileCard from '@/views/Admin/Corporate_materials/elements/FileCard.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    BRow,
    BCol,
    TitleText,
    SummaryContent,
    ImageCard,
    FileCard,
  },
}
</script>
